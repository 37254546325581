/// Theme colours
/// Inspired by https://tailwindcss.com/docs/customizing-colors
@import "utilities";

$colors: (
  "yellow": #ffd200,
  "error-red": #e62600,
  "teal": #00838f,
  "teal-dark": #00666f,
  "teal-light": #43a4ad,
  "teal-lighter": #daedee,
  "teal-lightest": #e3f2f3,
  "black": #000000,
  "white": #ffffff,
  "charcoal": #161b21,
  "dark-grey": #6d6e71,
  "grey": #c3c2c3,
  "mid-grey": #cccccc,
  "light-grey": #e2e3e3,
  "cloud-grey": #f1f1f1,
  "background-grey-warm": #f3f2ef,
  "background-grey-warmer": #f8f8f8,
  "readability-grey-dark": #333333,
  "readability-grey": #666666,
  "readability-grey-light": #808080,
  "readability-grey-lighter": #999999,
  "readability-grey-lightest": #dadada,
  // K2 colors
  "k2-neutrals-card-element-bg": #f2f2f3,
  "k2-neutrals-card-element-bg-hover": #eaebec,
  "k2-neutrals-primary-text": #3d4143,
  "k2-neutrals-secondary-text": #303436,
  "k2-neutrals-secondary-text-light": #6d7578,
  "k2-neutrals-quaternary-text": #8f9699,
  "k2-neutrals-disabled-text": #a9afb1,
  "k2-neutrals-separator": #eaebec,
  "k2-neutrals-surface-primary-text": #303436,
  "k2-neutrals-surface-bg": #f9fafa,
  "k2-primary-base": #1e5df1,
  "k2-primary-darken-1": #0a41c2,
  "k2-primary-darken-2": #0f348a,
  "k2-primary-darken-3": #0d2b73,
  "k2-primary-darken-4": #0a225c,
  "k2-primary-darken-5": #081a45,
  "k2-primary-darken-6": #05112e,
  "k2-primary-lighten-1": #3377ff,
  "k2-primary-lighten-2": #669eff,
  "k2-primary-lighten-3": #99beff,
  "k2-primary-lighten-4": #ccdfff,
  "k2-primary-lighten-5": #e5efff,
  "k2-primary-lighten-6": #f5f9ff,
  "k2-secondary-base": #2f3437,
  "k2-tegus-blue": #0f348a,
  "k2-error-base": #f24a0d,
);

/// Helper for getting a $colors value
@function color($key) {
  @return map-get-safe($colors, $key, color);
}
