@import "../styles/old/variables";

.ToastContainer {
  position: fixed;
  right: $ca-content-spacing;
  bottom: $ca-content-spacing;
  z-index: 99; // Less than the NavLayout overlay

  margin-left: $ca-content-spacing;

  transition: margin-right $ca-ui-transition-duration;
  .ToastDisplay.ToastDisplay--animate {
    animation: $ui-animation-slide-up;
  }
}
