@import "variables"; // Mixins depend on variables

/* Custom mixins */
@mixin anchor-style {
  // Same style as defined for "a" in "~bootstrap-scss/reboot"
  @include hover() {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
  color: $link-color;
  text-decoration: $link-decoration;
  background-color: transparent; // Remove the gray background on active links in IE 10.
  cursor: pointer;
}

@mixin hide-scrollbars {
  // Styles from https://stackoverflow.com/a/49278385
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
}

$l-icon-line-width: 0.125rem;
$l-icon-line-length: 0.75rem;
$l-icon-spacing-bottom-default: 0.125rem;
@mixin l-icon-right(
  $spacing-bottom: $l-icon-spacing-bottom-default,
  $spacing-right: 1.25rem
) {
  &:before {
    position: absolute;
    right: -$spacing-right;
    bottom: $spacing-bottom;

    width: $l-icon-line-length;
    height: $l-icon-line-width;

    background-color: $ca-yellow;

    content: "";
  }
  &:after {
    position: absolute;
    right: -$spacing-right;
    bottom: $spacing-bottom;

    width: $l-icon-line-width;
    height: $l-icon-line-length;

    background-color: $ca-yellow;

    content: "";
  }
}
@mixin l-icon-right-active($spacing-bottom: $l-icon-spacing-bottom-default) {
  &:before {
    bottom: $l-icon-line-length - $l-icon-line-width + $spacing-bottom;
  }
}

@mixin svg-button($size: 2.75rem, $padding: 0.5rem) {
  display: grid;
  align-items: center;
  justify-items: center;
  width: $size;
  height: $size;
  padding: 0;

  svg {
    max-width: $size - ($padding * 2);
    max-height: $size - ($padding * 2);
  }
}

/* Include Bootstrap mixins */
@import "~bootstrap-scss/mixins";
