@import "../styles/old/variables";
@import "../styles/colors";
@import "../styles/fonts";

$breadcrumb-h-spacing: 0.5rem;
$breadcrumb-v-spacing: 0.1rem;
$breadcrumb-chevron-height: 1.25rem;
$breadcrumb-chevron-width: 1rem;
$breadcrumb-chevron-width-with-spacing: $breadcrumb-chevron-width +
  ($breadcrumb-h-spacing * 2);
$breadcrumb-home-height: 1.125rem;
$breadcrumb-home-width: 1.125rem;
$breadcrumb-font-size: 1rem;

.Breadcrumbs {
  position: relative;

  padding-bottom: 0.75rem;

  color: color("readability-grey");
  font-size: $breadcrumb-font-size;
  line-height: 1;

  border-bottom: 0.063rem solid color("k2-neutrals-separator");
}

.Breadcrumbs .Breadcrumbs__container {
  overflow: hidden;
  direction: rtl;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Breadcrumbs .Breadcrumbs__container .Breadcrumbs__sub-container {
  display: inline;
  direction: ltr;
  unicode-bidi: isolate;
}

.Breadcrumbs .Breadcrumbs__chevron {
  width: $breadcrumb-chevron-width-with-spacing;
  height: $breadcrumb-chevron-height;
  padding-top: 0.275rem;
  padding-bottom: 0.175rem;

  color: color("k2-neutrals-quaternary-text");
}

.Breadcrumbs .Breadcrumbs__item .Breadcrumbs__item-text {
  @include font("tegus-button-small");

  color: color("k2-neutrals-quaternary-text");
  text-decoration: none;
  vertical-align: 0.15rem;
}

.Breadcrumbs
  .Breadcrumbs__item.Breadcrumbs__item--active
  .Breadcrumbs__item-text {
  color: color("k2-neutrals-secondary-text");
}

.Breadcrumbs .Breadcrumbs__item .Breadcrumbs__item-icon {
  color: color("k2-neutrals-quaternary-text");
  vertical-align: 0.06rem;

  svg {
    width: $breadcrumb-home-width;
    height: $breadcrumb-home-height;
    transform: scale(0.95, 1.05);
  }
}
