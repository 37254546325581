@import "../styles/colors";
@import "../styles/spacing";
@import "../styles/borders";
@import "../styles/fonts";

$checkbox-size: 1.1rem;
$checkbox-check-color: #fff;
$checkbox-vertical-align: middle;

$checkbox-tick-top: 22%;
$checkbox-tick-left: 13%;
$checkbox-tick-width: 0.75rem;
$checkbox-tick-height: 0.4rem;
$checkbox-tick-border: 0.13rem solid #222;
$checkbox-tick-border-top: none;
$checkbox-tick-border-right: none;
$checkbox-tick-rounding: 0.075rem;

// css checkbox
.Checkbox {
  .Checkbox__css-checkbox {
    display: none;

    + i {
      position: relative;

      display: inline-block;
      box-sizing: border-box;
      width: $checkbox-size;
      height: $checkbox-size;
      margin: 0;

      font-size: $checkbox-size;
      vertical-align: $checkbox-vertical-align;

      border: 0.0625rem solid color("readability-grey-lighter");
      border-radius: 0.1rem;
      cursor: pointer;

      transition: background 0.2s ease;

      &:before,
      &:after {
        position: absolute;
        top: $checkbox-tick-top;
        left: $checkbox-tick-left;

        box-sizing: border-box;
        width: $checkbox-tick-width;
        height: $checkbox-tick-height;

        background: rgba(0, 0, 0, 0);
        border: $checkbox-tick-border;
        border-top: $checkbox-tick-border-top;
        border-right: $checkbox-tick-border-right;
        border-radius: $checkbox-tick-rounding;
        outline: 0.0625rem solid transparent; // prevent antialias artifacts in Firefox
        transform: translateZ(0.0625rem) rotate(-45deg);
        opacity: 0;

        content: "";
      }

      &:hover {
        box-shadow: $teal-lightest-box-shadow;
        clip-path: circle(100%);
      }
    }

    &:checked + i {
      background: color("k2-primary-darken-2");
      border-color: color("k2-primary-darken-2");

      &:after {
        border-color: $checkbox-check-color;
        opacity: 1;
      }
    }

    &:disabled + i,
    &:disabled + i + span {
      cursor: auto;
      opacity: 0.3;
    }
  }
}

.Checkbox__checkbox-label {
  @include font("tegus-text-text");
  @include padding(0.7rem, "l");

  position: relative;
  display: inline-block;
  vertical-align: $checkbox-vertical-align;
  &:hover {
    cursor: pointer;
  }
}
