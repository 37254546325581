@import "../../styles/old/bootstrap";
@import "../../styles/spacing";

.ResetMfa__container {
  @include padding($ca-content-spacing/2, "b");
  .Card {
    height: 100%;
  }
  .Card__card {
    height: 100%;
    // Mimick the screen rules of col-lg
    /* stylelint-disable-next-line unit-allowed-list */
    @media only screen and (min-width: map-get($grid-breakpoints, "lg")) {
      .ButtonGroup {
        margin-top: auto;
      }
    }

    .Card__card-body {
      @include margins(0, "b");
      display: flex;
      flex-flow: column nowrap;
    }
  }
}
