@import "../styles/colors";
@import "../styles/fonts";
@import "../styles/spacing";

.FileUpload {
  display: flex;
  align-items: center;

  > .FileUpload__button {
    @include font("tegus-button");

    display: inline-block;
    margin-bottom: 0rem;
    padding: 0.25rem 0.75rem;

    color: color("k2-primary-darken-2");

    background-color: color("white");
    border: 0.06rem solid color("k2-primary-darken-2");
    border-radius: 0.375rem;
    cursor: pointer;

    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    -webkit-user-select: none;
    user-select: none;

    > input[type="file"] {
      display: none;
    }
  }
  > .FileUpload__button:hover {
    background-color: color("k2-primary-lighten-4");
    border-color: color("k2-primary-base");
    border-width: 0.06rem;
  }
  > .FileUpload__filename {
    padding: 0.25rem 0.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  > .FileUpload__clear {
    margin-right: 0.15rem;
    margin-left: 0.5rem;
  }
}

.ClearButton {
  color: color("k2-primary-darken-2");
}
.ClearButton--disabled {
  color: color("k2-neutrals-disabled-text");
}
