@import "../styles/old/variables";
@import "../styles/colors";
@import "../styles/borders";

$form-spacing: 0.5rem;

$input-padding-y: 0.5rem;
$input-padding-x: 1rem;

@mixin field {
  margin-bottom: $form-spacing;
}

@mixin field__label {
  max-width: 100%;
  font-weight: $input-font-weight;
  cursor: auto;
}

@mixin field__input {
  width: 100%;
  padding: $input-padding-y $input-padding-x;

  border: 0.063rem solid color("k2-neutrals-separator");
  border-radius: $input-border-radius;
  outline: none;
}
@mixin field__input--hover {
  border: $input-border-active;
}
@mixin field__input--focus {
  border: $input-border-active;
}

@mixin field__error {
  border-color: $input-border-error;
}
