@import "../styles/borders";
@import "../styles/colors";
@import "../styles/old/variables";

@mixin dropdown-item {
  position: relative;

  display: block;
  width: 100%;
  padding-top: 0.5rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;

  color: color("black");
  line-height: 1.5rem;
  text-decoration: none;

  background-color: color("white");
  border-right: $input-border-base;
  border-left: $input-border-base;
  cursor: pointer;

  user-select: none;

  &:first-child {
    border-top: $input-border-base;
    border-top-left-radius: $input-border-radius;
    border-top-right-radius: $input-border-radius;
  }
  &:last-child {
    border-bottom: $input-border-base;
    border-bottom-right-radius: $input-border-radius;
    border-bottom-left-radius: $input-border-radius;
  }
  &:hover {
    text-decoration: none;
    background-color: color("background-grey-warmer");
  }
}
