@import "../styles/colors";
@import "../styles/fonts";
@import "../styles/icons";
@import "../styles/old/mixins";
@import "../styles/borders";

$side-nav-icon-size: 2rem;
$side-nav-background: color("k2-neutrals-surface-bg");
$side-nav-hover-background: color("yellow");

.SideNav {
  @include hide-scrollbars;

  position: fixed;
  top: $ca-top-nav-height;
  bottom: 0;
  left: 0;
  z-index: 99; // One less than the <TopNav />

  width: $ca-left-nav-width;
  padding: $ca-content-spacing 0;
  overflow-y: scroll;

  background: $side-nav-background;
  border-right: 0.063rem solid color("k2-neutrals-separator");
}

.SideNav__ul {
  @include font("tegus-text-med");

  color: color("k2-neutrals-primary-text");
  user-select: none;
}

.SideNav__li:not(:last-child) {
  margin-bottom: 0.5rem;
}

.SideNav__li > a,
.SideNav__li .Dropdown__toggle {
  display: flex;
  align-items: center;
  padding: 0rem 0.5rem 0rem 2rem;
  text-decoration: none;

  span {
    line-height: $side-nav-icon-size;
  }
  svg {
    @include icon-color("k2-neutrals-disabled-text");
    flex-basis: $side-nav-icon-size;
    height: 100%;
    max-height: $side-nav-icon-size;
    margin-right: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.5rem;
  }
}

.SideNav__li > a:hover,
.SideNav__li.SideNav__li--active > a,
.SideNav__li .Dropdown__toggle:hover {
  position: relative;
  text-decoration: none;
  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1rem;

    width: 0.25rem;

    background: color("k2-primary-base");

    content: "";
  }
}

.SideNav__li.SideNav__li--active {
  > a > svg {
    @include icon-color("k2-primary-base");
  }
  > a > span {
    color: color("k2-primary-base");
  }
}
