.UserSettings {
  flex-grow: 1;
}

.UserSettings.UserSettings--loading-spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
