@import "../styles/old/variables";
@import "../styles/colors";
@import "./mixins";

$form-spacing: 1rem;

.TextField {
  input {
    @include field__input;
  }

  input[type="password"] {
    font-family: Verdana, sans-serif; // Produces rounder larger dots for password input
    letter-spacing: 0.25rem;
  }

  textarea {
    @extend input;
    height: 10rem;
    vertical-align: top;
    resize: none;
  }

  & .TextField--invalid {
    input,
    textarea {
      @include field__error;
    }
  }

  input:focus,
  textarea:focus {
    @include field__input--focus;
  }

  input:hover,
  textarea:hover {
    @include field__input--hover;
  }
}

.TextField__header {
  display: flex;
  align-items: flex-end;
  margin-bottom: $label-margin-bottom;
  label.TextField__label {
    margin-bottom: 0;
  }
}

.TextField__label {
  @include field__label;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.TextField__label-asterix {
  color: color("k2-error-base");
}

.TextField__charlimit {
  @include field__label;
  margin-left: $label-margin-bottom;
  color: $ca-dark-grey;
  font-size: $font-size-sm;
  vertical-align: text-bottom;
}
.TextField__charlimit-count--error {
  color: $ca-error-red;
}

.TextField__error {
  display: block;
  color: $ca-error-red;
  font-weight: $input-error-font-weight;
  font-family: $ca-font-family-inter;
}

.TextField__wrapper {
  display: flex;
}

.TextField__show-password-button {
  width: 2.5rem;
  color: color("k2-primary-darken-2");
  background-color: transparent;
  border: 0;
  &:hover {
    color: $ca-black;
    cursor: pointer;
  }
}
