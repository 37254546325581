@import "../../styles/colors";
@import "../../styles/icons";

// The bottom of the star has more visual weight, so nudge it up a tad
$star-offset-y: -0.05rem;

.CompaniesTableWatchListButton {
  position: relative;
  display: flex;
  align-items: center;
  height: 65%;
}

.CompaniesTableWatchListButton .CompaniesTableWatchListButton__star-icon {
  @include icon-color("grey");
  position: absolute;
  transform: translateY($star-offset-y);
}
.CompaniesTableWatchListButton.CompaniesTableWatchListButton--on
  .CompaniesTableWatchListButton__star-icon {
  @include icon-color("k2-primary-base");
}

.CompaniesTableWatchListButton .CompaniesTableWatchListButton__star-bg-icon {
  @include icon-color("white");

  // Slightly smaller than the foreground start icon; helps the edges look better on
  // non-white backgrounds. Also the solid icon is extra thicc for some reason
  transform: translateY($star-offset-y) scale(0.95);
}
.CompaniesTableWatchListButton.CompaniesTableWatchListButton--on
  .CompaniesTableWatchListButton__star-bg-icon {
  @include icon-color("k2-primary-base");
}
