@import "../../../styles/old/variables";
@import "../../../styles/spacing";

.WatchListModal {
  .WatchListModal__body {
    @include margins(1rem, "b");
    position: relative;
    height: 20.625rem;
    animation: $ui-animation-fade-in;

    .WatchListModal__user-list {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
    }
  }
  .WatchListModal__frame {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    height: 27.125rem;

    .WatchListModal__error-msg {
      text-align: center;
    }
  }
}
