@import "../styles/old/variables";

$modal-display-min-width: 50%;
$modal-display-z-index: 100;

.ModalContainer {
  position: fixed;
  top: 0;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  visibility: hidden;

  .Card {
    z-index: $modal-display-z-index;
    min-width: $modal-display-min-width;
    visibility: visible;
    animation: $ui-animation-slide-up;
  }
  .Card .Card__card-body {
    max-height: 90vh;
  }

  .ModalContainer__wrapper.ModalContainer__wrapper--animate {
    visibility: visible;
    animation: $ui-animation-fade-in;
  }
}

.ModalContainer__wrapper {
  position: absolute;
  z-index: $modal-display-z-index - 1;

  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.6);
}
