@import "../styles/old/variables";

.FormError {
  &__message {
    display: block;
    color: $ca-error-red;
    font-weight: $input-error-font-weight;
    font-family: $ca-font-family-inter;
  }
}
