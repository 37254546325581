@import "../styles/colors";
@import "../styles/spacing";

$input-border-width: $one-px;
$input-border-radius: 0.375rem;

$black-box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.12);
$teal-lightest-box-shadow: 0 0 0 1rem color("k2-primary-lighten-4");

$input-border-base: $input-border-width solid color("k2-neutrals-separator");
$input-border-active: $input-border-width solid color("k2-primary-base");
$input-border-error: $input-border-width solid color("error-red");
