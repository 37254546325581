@import "../forms/mixins";
@import "../styles/colors";

.ContactUsForm .Dropdown__content {
  width: 100%;
}

.ContactUsForm__model-request-notes {
  margin-bottom: $form-spacing;
  .ContactUsForm__alert {
    color: color("dark-grey");
  }
  .ContactUsForm__alert:not(:last-child) {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
}
