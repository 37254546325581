@import "../../styles/old/variables";
@import "../../styles/colors";
@import "../../styles/spacing";

.MultiSelectList {
  .MultiSelectList__title {
    @include margins(0.5rem, "b");
    font-weight: 500;
  }
  .MultiSelectList__description {
    @include margins(1rem, "b");
  }
  .MultiSelectList__frame {
    @include padding(1rem);
    display: flex;
    flex-flow: column nowrap;
    background-color: color("cloud-grey");
    border-radius: 0.5rem;

    .MultiSelectList__search-bar {
      @include margins(0.5rem, "b");
      display: flex;
      .MultiSelectList__search-field {
        @include padding(0.5rem, "l");
        @include margins(0.12rem, "l");
        display: flex;
        width: 100%;

        background-color: color("cloud-grey");
        border: 0rem;
        border-bottom: 0.125rem solid color("readability-grey-lightest");
        border-radius: 0rem;
      }
      .MultiSelectList__search-clear {
        @include padding(0.5rem, "x");
        @include padding(0.12rem, "b");
        @include margins(0.12rem, "r");
        display: flex;
        align-items: flex-end;
        border-bottom: 0.125rem solid color("readability-grey-lightest");
      }
    }

    .MultiSelectList__items {
      display: flex;
      flex-flow: column nowrap;
      flex-grow: 2;
      gap: 0.25rem;
      overflow-x: hidden;
      overflow-y: auto;

      border-radius: 0.5rem;

      .MultiSelectList__spinner {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      .MultiSelectList__search-hint {
        @include padding(0.5rem, "l");
        @include margins(0.5rem, "t");
        color: color("dark-grey");

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }

    .MultiSelectList__footer {
      @include padding(1rem, "t");
      display: flex;
      justify-content: space-between;
      animation: $ui-animation-slide-up;

      .MultiSelectList__change-summary {
        @include padding(0.5rem, "l");
        color: color("readability-grey-lighter");
      }
      .MultiSelectList__buttons {
        display: flex;
        gap: 0.5rem;
        justify-content: flex-end;
      }
    }
  }
}
