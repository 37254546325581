@import "../styles/colors";
@import "../styles/fonts";
@import "../styles/spacing";

$border: 0.07rem solid color("mid-grey");

.CompaniesFull {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  display: flex;
  flex-flow: column nowrap;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  background-color: color("white");

  .CompaniesFull__header {
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4rem;

    color: color("readability-grey-dark");
    font-weight: 400;
    font-size: 1.5rem;
    font-family: font-family("inter");
    line-height: 1.2;

    background-color: color("white");
    border-bottom: $border;

    .CompaniesFull__header-left {
      @include padding(1rem, "l");

      display: flex;
      gap: 1rem;
      align-items: center;

      .CompaniesFull__header-logo {
        @include padding(0.25rem, "l");
      }

      .CompaniesFull__header-text {
        @include margins(3rem, "r");
      }
    }

    .CompaniesFull__header-right {
      @include padding(1rem, "r");
      display: flex;
      gap: 0.75rem;
      align-items: center;

      .CompaniesFull__quick-search {
        display: flex;
        width: 30rem;
        font-size: 1rem;

        .CompaniesFull__quick-search-icon {
          @include margins(0.5rem, "r");

          display: flex;
          align-items: center;
          color: #999;
        }

        .CompaniesFull__quick-search-field {
          @include padding(0.5rem, "l");
          @include padding(0.25rem, "r");
          @include padding(0.25rem, "y");

          display: flex;
          width: 100%;
          height: 2.5rem;

          border-top: $border;
          border-right: 0rem;
          border-bottom: $border;
          border-left: $border;
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
        }
        .CompaniesFull__quick-search-field:focus {
          outline-width: 0;
        }
        .CompaniesFull__quick-search-clear {
          @include padding(0.75rem, "x");
          @include padding(0.12rem, "t");
          @include margins(0.12rem, "r");

          display: flex;
          align-items: center;

          border-top: $border;
          border-right: $border;
          border-bottom: $border;
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
        }
      }
    }
  }

  .CompaniesFull__body {
    display: flex;
    flex-flow: row nowrap;
    min-width: 53.25rem;
    height: 100%;

    .CompaniesFull__sidebar-column {
      max-height: calc(100vh - 4.05rem);

      .CompaniesFull__sidebar-column-frame {
        height: 100%;
        border-right: $border;
      }
    }
    .CompaniesFull__table-column {
      display: flex;
      width: 100%;

      .CompaniesFull__table-loading {
        display: flex;
        flex-flow: column nowrap;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.CompaniesFull__loading {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 17.5rem);
}

*::-webkit-scrollbar-track {
  display: none;
}
