@import "../styles/icons";
@import "../styles/spacing";

.CreatedUsers {
  .CreatedUsers__envelope-icon-parent {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  svg.CreatedUsers__envelope-icon {
    @include icon-color("readability-grey-lighter");
    @include margins(0.5rem, "l");
    max-width: 2rem;
    max-height: 2rem;
  }

  .CreatedUsers__footer {
    @include margins(1rem, "t");
  }
}
