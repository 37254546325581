@import "../index.scss";

.ContactUs {
  display: flex;
  flex: 1;

  .Card {
    align-items: baseline;
    justify-content: start;
    width: 100%;
  }
}

.ContactUs__loading {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.ContactUs__loading--spinner {
  display: flex;
}
