@import "../../styles/old/mixins";
@import "../../styles/spacing";
@import "../../styles/fonts";

.SearchDisplay {
  flex-grow: 1;
}
.SearchDisplay.SearchDisplay--loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.SearchDisplay__footer {
  @include padding(1rem, "t");
  display: flex;
}

.SearchDisplay__button {
  @include padding(1rem, "x");
  height: 2.5rem;
}

.SearchDisplay__page-num {
  display: grid;
  flex-grow: 1;
  align-items: center;
  justify-items: center;

  color: $text-muted;
  font-size: $font-size-sm;
}

.SearchDisplay__search-hint {
  font-style: italic;
  p {
    @include font("tegus-text-text");
    margin-bottom: 0.75rem;
  }
}
.SearchDisplay__hint-action {
  @include anchor-style();
}
