@import "../../styles/old/bootstrap";
@import "../../styles/colors";
@import "../../styles/spacing";
@import "../../styles/fonts";

@mixin default-dimensions {
  @include padding(1.25rem, "x");
  @include padding(1rem, "y");
  height: 3rem;
  border-radius: 0.5rem;
}

@mixin button-light-style {
  background-color: white;
  border-color: color("readability-grey-lighter");
  border-width: $one-px;
  &:hover {
    color: color("k2-primary-base");
    background-color: color("k2-primary-lighten-4");
    border-color: color("k2-primary-base");
    border-width: $one-px;
  }
}

.Button {
  display: inline-flex;
  align-items: center;

  white-space: $btn-white-space;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;

  border: $btn-border-width solid transparent;

  transition: $btn-transition;

  user-select: none;
  &:hover {
    text-decoration: none;
  }
  &:not(:disabled) {
    cursor: pointer;
  }
}

.Button--secondary {
  @include default-dimensions();
  @include button-light-style();
  @include font("button-secondary");
}

.Button--primary {
  @include default-dimensions();
  @include font("button-primary");
  background-color: color("k2-primary-base");
  border-color: color("k2-primary-base");
  border-width: $one-px;
  &:hover {
    color: white;
    background-color: color("k2-primary-lighten-4");
    border-color: color("k2-primary-lighten-4");
  }
}

.Button--primary-slim {
  @include button-light-style();
  @include font("button-primary-slim");

  @include padding(0.75rem, "x");
  @include padding(0.5rem, "y");
  height: 1.5rem;

  line-height: font-prop("button-tertiary", "font-size");

  background-color: color("k2-primary-base");
  border-color: color("k2-primary-base");
  border-radius: 0.75rem;
  &:hover {
    color: white;
    background-color: color("k2-primary-lighten-4");
    border-color: color("k2-primary-lighten-4");
  }
}

.Button--tertiary {
  @include button-light-style();
  @include font("button-tertiary");
  @include padding(0.75rem, "x");
  @include padding(0.5rem, "y");
  height: 1.5rem;
  line-height: font-prop("button-tertiary", "font-size");
  border-radius: 0.75rem;
}

.Button--tegus-primary {
  @include default-dimensions();
  @include font("tegus-button");

  color: color("white");
  background-color: color("k2-primary-darken-2");

  &:hover {
    background-color: color("k2-primary-base");
  }
}

.Button--tegus-secondary {
  @include default-dimensions();
  @include font("tegus-button");

  color: color("k2-primary-darken-2");
  background-color: color("white");
  border: 0.125rem solid color("k2-primary-darken-2");

  &:hover {
    background-color: color("k2-primary-lighten-4");
  }
}

.Button--tegus-secondary-slim {
  @include padding(0.625rem, "x");
  @include padding(0.25rem, "y");
  @include font("tegus-text-caption-semi");

  color: color("k2-primary-darken-2");
  background-color: color("white");
  border: 0.067rem solid color("k2-primary-darken-2");
  border-radius: 5rem;

  &:hover {
    background-color: color("k2-primary-lighten-4");
  }
}

.Button--tegus-tertiary {
  @include default-dimensions();
  @include font("tegus-button");

  color: color("k2-secondary-base");

  &:hover {
    background-color: color("k2-neutrals-card-element-bg-hover");
  }
}

.Button--tegus-tertiary-alt {
  @include padding(0.5rem, "x");
  @include font("tegus-button");

  color: color("k2-primary-darken-2");
  border-radius: 0.5rem;

  &:hover {
    color: color("k2-primary-darken-2");
    background-color: color("k2-primary-lighten-4");
  }
}

.Button--disabled {
  color: color("light-grey");

  background-color: color("white");
  border-color: color("light-grey");
  border-width: $one-px;

  pointer-events: none;
}
