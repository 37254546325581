@import "../../styles/old/variables";

.ApiTokensCreateForm__error {
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;

  color: $ca-error-red;
  font-weight: $input-error-font-weight;
  font-family: $ca-font-family-inter;
}
