@import "../styles/old/variables";
@import "../styles/colors";

.ToastDisplay {
  display: inline-flex;
  max-width: 32rem;

  // Include some extra space at the bottom for the border/shadow. Don't use the full
  // border width though as aesthetically (subjectively) it looks too spacious / "off".
  padding-bottom: $ca-border-width-thick / 2;

  color: white;
  text-overflow: ellipsis;

  background-color: $ca-charcoal;
  box-shadow: 0 0 -$ca-border-width-thick 0 color("k2-tegus-blue") inset;

  &.ToastDisplay--error {
    box-shadow: 0 0 -$ca-border-width-thick 0 $ca-error-red inset;
  }
}

.ToastDisplay .ToastDisplay__text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;

  // Since the text is usually plain without any HTML, we can (somewhat) safely
  // assume that <span> elements are from <Action /> components.
  a,
  span {
    color: white;
    text-decoration: underline;
    cursor: pointer;
  }
}

.ToastDisplay .ToastDisplay__close-btn {
  // Include padding here so the close click target is larger
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  svg {
    width: 1.4rem;
    height: 1.4rem;
    fill: white;
  }
  &:hover {
    cursor: pointer;
    svg {
      fill: $ca-yellow;
    }
  }
}
