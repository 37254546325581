.CompaniesSidebar {
  width: 21rem;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  ul {
    list-style: none;
    padding-inline-start: 0;

    li {
      position: relative;
      padding-left: 0rem !important;

      &::before {
        display: none;
      }
    }
  }

  .CompaniesSidebar__list-frame {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
