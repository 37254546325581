/// Spacing, for almost everything
/// Inspired by the "spacing" and "sizing" utilities of Tailwind
@import "utilities";

// Variable to avoid the need to escape styelint errors when using 1px
/* stylelint-disable unit-allowed-list */
$one-px: 1px;
/* stylelint-enable unit-allowed-list */

/// Add padding to an element
///
/// Examples:
///   @include padding(0.5rem)   0.5rem of padding on all sides
///   @include padding(1rem, "l")   1rem of padding of the left side
///   @include padding(1.5rem, "y")   1.5rem of padding on the top & bottom
@mixin padding($spacing, $direction-props-key: "*") {
  $_: assert-number($spacing);
  $direction-props: direction-props($direction-props-key);

  @each $prop in $direction-props {
    padding-#{$prop}: $spacing;
  }
}

/// Add margins to an element (see docs for padding)
@mixin margins($spacing, $direction-props-key: "*") {
  $_: assert-number($spacing);
  $direction-props: direction-props($direction-props-key);

  @each $prop in $direction-props {
    margin-#{$prop}: $spacing;
  }
}

/// Add space between direct child elements
///
/// Examples:
///   @include space(1rem, "x")  /* 1rem of space between all child elements */
@mixin space($spacing, $direction) {
  $_: assert-number($spacing);
  $spacing-half: $spacing / 2;

  @if $direction == "y" {
    margin-top: -$spacing-half;
    margin-bottom: -$spacing-half;
    & > * {
      margin-top: $spacing-half;
      margin-bottom: $spacing-half;
    }
  } @else if $direction == "x" {
    margin-right: -$spacing-half;
    margin-left: -$spacing-half;
    & > * {
      margin-right: $spacing-half;
      margin-left: $spacing-half;
    }
  } @else {
    @error "#{$direction} is not a valid direction";
  }
}
