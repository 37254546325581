@import "../../styles/colors";
@import "../../styles/spacing";
@import "../../styles/fonts";

.IpoSubscriptions {
  @include margins(0.75rem, "t");
}

.IpoSubscriptions__title {
  @include margins(1rem, "b");
  @include font("tegus-text-subtitle-1");
}

.IpoSubscriptions__description {
  @include margins(1rem, "b");
  @include font("tegus-text-text");
  color: color("k2-neutrals-secondary-text-light");
}

.IpoSubscriptions__all-companies {
  @include margins(0.75rem, "b");
}

.IpoSubscriptions__checkbox-list {
  @include margins(1rem, "b");
  @include margins(2.25rem, "r");
  flex: 1 1 auto;
}

.FlexStart {
  justify-self: flex-start;
}

.IpoSubscriptions__checkbox-lists {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 40rem;
}
