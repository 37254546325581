@import "../../styles/old/variables";
@import "../../styles/spacing";
@import "../../styles/colors";

.BrowseDisplay {
  position: relative;
  flex-grow: 1;

  .BrowseDisplay__staff-buttons {
    padding-top: 1.5rem;
  }

  .BrowseDisplay__overlay {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

.BrowseDisplay.BrowseDisplay--loading {
  @include margins($grid-gutter-width, "t");
  display: flex;
  align-items: center;
  justify-content: center;
}

.BrowseDisplay:not(.BrowseDisplay--loading) .Spinner {
  @include margins($grid-gutter-width, "t");
}
