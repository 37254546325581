@import "../styles/colors";
@import "../styles/spacing";
@import "../styles/fonts";

.CheckboxList {
  color: color("readability-grey-dark");
}

.CheckboxList__checkbox-item {
  @include margins(0, "b");
}

.CheckboxList__heading {
  @include font("tegus-text-subtitle-1");
  @include margins(1rem, "b");
}
