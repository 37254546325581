@import "../styles/old/bootstrap";
@import "../styles/colors";
@import "../styles/spacing";
@import "../styles/fonts";
@import "../styles/borders";

$card-card-margin-threshold: 36em;

.Card {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Card .Card__card {
  width: 100%;
  max-width: none;
  margin: 0;

  color: color("k2-neutrals-primary-text");

  border: 0.063rem solid color("k2-neutrals-separator");
  border-radius: $border-radius;

  > .Card__card-title {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 1.5rem 0rem 1.5rem;

    > h2 {
      @include font("tegus-headline-3");
      @include margins(1rem, "b");
      @extend .card-title;

      color: color("k2-neutrals-secondary-text");
    }
  }
  > .Card__card-title--coloured {
    padding: 1rem 1.5rem 1rem 1.5rem;
    background-color: color("k2-primary-darken-2");
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;

    > h2 {
      margin-bottom: 0rem;
      color: color("white");
    }
  }
  > .Card__card-body {
    padding: 0rem 1.5rem 1.5rem 1.5rem;
  }
  > .Card__card-body--coloured {
    padding: 1.5rem;
  }
  > .Card__card-body--with-scrollbar {
    overflow: auto;
  }

  > div {
    > p {
      @include font("tegus-text-text");
      @include margins(1rem, "b");
      @extend .card-text;
      color: color("k2-neutrals-secondary-text-light");
    }
    > img,
    span > img,
    a > img {
      @include margins(0, "t");
      @include margins(1rem, "b");
      @extend .card-img-top;
      border: $one-px solid $ca-grey;
      border-radius: $border-radius;
      box-shadow: $black-box-shadow;
    }
    > h4,
    span > h4,
    a > h4 {
      @include font("tegus-text-large-bold");
      @include margins(1rem, "b");
      color: color("k2-neutrals-secondary-text");
    }
    > a {
      text-decoration: none;
    }
    > span {
      cursor: pointer;
    }
  }
}

.Card.Card--highlighted {
  background: color("k2-primary-lighten-2");
  border-radius: $border-radius;
  box-shadow: 0 0 0 0.125rem color("k2-primary-base");
  > .Card__card {
    background: inherit;
  }
}

.Card__card-body .Card .Card__card {
  border: none;
  box-shadow: none;
  > .Card__card-title {
    padding: 0;
  }
  > .Card__card-body {
    height: 100%;
    padding: 0;
  }
}

.Card.Card--tegus .Card__card {
  background: color("k2-neutrals-surface-bg");
  border: none;
  box-shadow: none;

  > .Card__card-title {
    padding: 0;
  }
  > .Card__card-body {
    padding: 0;
  }
}

.Card.Card--with-margins .Card__card {
  margin: 3rem;
  border: none;
}

.Card.Card--with-no-margins .Card__card {
  margin: 0rem;
}

.Card.Card--max-width-standard .Card__card {
  max-width: 40rem;
}

.Card.Card--max-width-wide .Card__card {
  max-width: 60rem;
}

@media only screen and (max-width: $card-card-margin-threshold) {
  .Card.Card--with-margins .Card__card {
    margin: 0;
  }
}
