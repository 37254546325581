@import "../../styles/colors";
@import "../../styles/spacing";

.CompaniesTableRowActions {
  display: flex;
  flex-grow: 1;
  height: 100%;

  .CompaniesTableRowActions__row-end {
    flex-grow: 1;
    background: color("cloud-grey");
  }

  .CompaniesTableRowActions__buttons {
    @include padding(0.5rem, "x");

    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    align-items: center;
    justify-content: space-evenly;

    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .CompaniesTableRowActions__spacer-end {
    flex-grow: 0;
    width: 1.5rem;
  }
}

.SvgIcon {
  transform: scale(0.625);
  transform-origin: center;
}
