@import "../styles/colors";
@import "../styles/spacing";
@import "../styles/icons";
@import "../styles/old/variables";

.NavMenu {
  display: flex;
  flex-shrink: 0;
  gap: 1rem;
  align-items: center;
  padding-left: $ca-content-spacing;
  &__dropdown {
    margin-left: -0.5rem;
    color: color("black");
    cursor: pointer;
    user-select: none;
    svg {
      @include icon-color("k2-neutrals-primary-text");
      height: 2.5rem;
      margin: 0 0.1rem 0 0.1rem;
    }
    .Dropdown__toggle {
      display: flex;
      padding: 0.5rem;
    }
    &:hover {
      background: color("k2-neutrals-separator");
      border-radius: 0.5rem;
    }
  }
  &__helpIcon {
    svg {
      @include icon-color("k2-neutrals-primary-text");
      height: 1.5rem;
    }
  }
  &__divider {
    height: 2.5rem;
    margin: 0 0.5rem 0 0.5rem;
    color: color("k2-neutrals-primary-text");
    border-right: 0.2rem solid currentColor;
  }
  &__dropdownIcon {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;

    color: color("k2-neutrals-primary-text");
    letter-spacing: -0.06rem;

    border: 0.2rem solid currentColor;
    border-radius: 50%;
  }
  &__initials {
    margin: auto;
  }
  &__name {
    align-self: center;
    margin: 0 0.5rem 0 0.5rem;
  }
}
