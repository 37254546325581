@import "../../styles/old/variables";
@import "../../styles/spacing";
@import "../../styles/fonts";
@import "../../styles/colors";
@import "./HomePage";

.WhatsNew .WhatsNew__feature-updates {
  @include margins(-$grid-gutter-width, "b");
}

.WhatsNew__spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  /* An arbitrary value for what would look good based on the average
   * PromoForDisplay size and the size of the other items on the home page for
   * the average user */
  min-height: 44.25rem;
}

.WhatsNew__title {
  @include font("tegus-text-subtitle-1");

  margin-bottom: 1rem;
  color: color("k2-neutrals-quaternary-text");
}
