@import "../styles/colors";
@import "../styles/icons";
@import "../styles/old/variables";
@import "../styles/spacing";
@import "../styles/fonts";

$height: 4rem;
$item-spacing-x: 1rem;
$item-sa-spacing-x: 1rem;
$primary-icon-size: $height - $item-spacing-x * 2;
$inter-fli-spacing: 0.5rem;

@mixin icon-styles(
  $icon-size,
  $padding-l: $item-spacing-x,
  $padding-r: $item-spacing-x,
  $hover-effect: true
) {
  position: relative;

  display: flex;
  flex-basis: $icon-size + $padding-l + $padding-r;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  height: $height;

  @if $hover-effect {
    &:hover::after {
      $hover-bg-size: $icon-size + 1.25rem;

      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;

      width: $hover-bg-size;
      height: $hover-bg-size;

      background: color("k2-primary-lighten-5");
      border-radius: $hover-bg-size / 2;
      transform: translate(-50%, -50%)
        translateX(($padding-l - $padding-r) / 2);

      content: "";
      pointer-events: none;
    }
    &:hover svg {
      @include icon-color("k2-primary-base");
    }
  }

  svg {
    /* padding applied to inner element s.t. the padding is clickable */
    @include padding($padding-l, "l");
    @include padding($padding-r, "r");
    @include icon-color("k2-secondary-base");
    z-index: 2;
    max-width: $icon-size + $padding-l + $padding-r;
    max-height: $icon-size;
  }
}

.FancyListItem {
  position: relative;

  display: flex;
  align-items: center;
  height: $height;

  background: color("white");
  border: 0.063rem solid color("k2-neutrals-separator");
  border-radius: $border-radius;

  &:hover {
    background: color("k2-primary-lighten-5");
    cursor: pointer;
  }
  &:not(:last-child) {
    margin-bottom: $inter-fli-spacing;
  }
  &.FancyListItem--highlighted {
    background: color("k2-primary-lighten-5");
    // Use `box-shadow` instead of `border` so the border doesn't take up space.
    // 0.125rem (or 2px) is around the same width as the strokes in the icons
    box-shadow: 0 0 0 0.125rem color("k2-primary-lighten-2");
  }

  a,
  a:hover {
    text-decoration: none;
  }
  .FancyListItem__hover-show {
    display: none;
  }
  &:hover .FancyListItem__hover-show {
    display: inline;
  }

  .FancyListItem__path-separator {
    &::after {
      @include padding(0.25rem, "x");
      position: relative;
      top: -0.05rem;
      content: "›";
    }
  }
}

.FancyListItem__main-icon {
  @include icon-styles(
    $primary-icon-size,
    $item-spacing-x,
    $item-spacing-x,
    false
  );
  > svg > path {
    fill: color("k2-primary-lighten-3");
  }
}

.FancyListItem__text-container {
  @include padding($item-spacing-x, "r");
  $subtext-color: color("k2-neutrals-quaternary-text");
  position: relative;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;
  height: 100%;
  overflow-x: hidden;

  & > div {
    @include font("tegus-text-med");
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .FancyListItem__subtext {
    @include font("tegus-text-caption");
    color: $subtext-color;
    a {
      color: $subtext-color;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .FancyListItem__text-separator {
    @include padding(0.5rem, "x");
    display: inline-block;
  }

  // An overlay is used since <a> elements cannot contain other <a> elements.
  // Since the subtext sometimes uses <a> elements (e.g. when linking to a
  // containing folder in search results) the action overlay must be a sibling
  // element to the subtext.
  //
  // We take advantage of implicit stacking rules without using z-index as using
  // z-index explicitly causes stacking issues with elements outside of the FLI.
  // The implied stacking is carefully managed as follows (bottom to top):
  //
  // 1. Most text in the text container
  // 2. The action overlay (it is explicitly positioned)
  // 3. (Sub)text actions (explicitly positioned and later in the HTML)
  //
  // See here for more info:
  //   https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/Stacking_without_z-index
  .FancyListItem__text-action-overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }
  .FancyListItem__text-action {
    position: relative;
  }
}

.FancyListItem--has-secondary-actions {
  @include padding($item-sa-spacing-x / 2, "r");
}

.FancyListItem--has-secondary-actions .FancyListItem__text-container {
  @include padding($item-sa-spacing-x / 2, "r");
}

$secondary-icon-size: 1.5rem;

// Needs to be outside FancyListItem__secondary-action-container otherwise it is seen
// as too specific and means specific secondary action cannot easily overwrite
// properties (such as colour)
.FancyListItem__secondary-action {
  @include icon-styles(
    $secondary-icon-size,
    $item-sa-spacing-x / 2,
    $item-sa-spacing-x / 2
  );
}

.FancyListItem__secondary-action-container {
  flex-grow: 0;
  flex-shrink: 0;
}

.FancyListItem .FancyListItem__secondary-action-container--disabled {
  cursor: default;

  .FancyListItem__secondary-action {
    pointer-events: none;

    svg {
      @include icon-color("grey");
    }
  }
}

:export {
  /* stylelint-disable property-no-unknown */
  fliHeightWithBorder: $height;
  interFliSpacing: $inter-fli-spacing;
  /* stylelint-enable property-no-unknown */
}
