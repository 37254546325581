@import "../search_box/SearchBox";
@import "../styles/colors";
@import "../styles/icons";
@import "../styles/old/variables";
@import "../styles/spacing";

.TopNav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;

  display: flex;
  height: $ca-top-nav-height;
}

.TopNav__left-section {
  @include padding(0.75rem, "l");

  display: flex;
  flex-basis: $ca-left-nav-width;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;

  background: color("k2-neutrals-surface-bg");
  border-right: 0.063rem solid color("k2-neutrals-separator");
}

.TopNav__right-section {
  @include padding($ca-content-spacing, "x");

  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;

  background: color("k2-neutrals-surface-bg");
}

.TopNav .SearchBox {
  margin-right: auto;
}

$icon-spacing: 0.5rem;
.TopNav__right-section-icon {
  @include margins($icon-spacing / 2, "l");
  @include padding(0.5rem, "y"); // Bigger click area

  display: flex;
  flex-basis: 5rem;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;

  &:not(:last-child) {
    @include margins($icon-spacing / 2, "r");
  }
  &:hover {
    text-decoration: none;
  }

  svg {
    @include icon-color("white");
    height: 1.75rem;
  }

  div {
    @include margins(0.25rem, "t");
    color: color("white");
    font-size: $font-size-sm;
    user-select: none;
  }
}
