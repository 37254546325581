@import "../styles/icons";
@import "../styles/old/variables";
@import "./SearchBox";

$search-icon-size: 1.25rem; // Defined in <svg viewBox=...> attribute
$search-icon-spacing-x: 0.75rem;

$result-height: $search-box-height;

.SearchResult {
  position: relative;

  display: flex;
  align-items: center;
  height: $result-height;
  padding-right: $search-box-outer-spacing-x;

  user-select: none;

  &.SearchResult--selected {
    background-color: $ca-cloud-grey;
  }
  &:last-child {
    border-radius: 0 0 $search-box-radius $search-box-radius;
  }
}

.SearchResult__icon {
  flex-basis: $search-box-outer-spacing-x + $search-icon-size +
    $search-icon-spacing-x;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: $search-icon-spacing-x;
  padding-left: $search-box-outer-spacing-x;
  svg {
    @include icon-color("k2-primary-lighten-3");
    max-width: $search-icon-size;
    max-height: $search-icon-size;
  }
}

.SearchResult__text-container {
  position: relative;

  flex-grow: 1;
  flex-shrink: 1;
  height: $result-height;
  overflow: hidden;

  > div {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.SearchResult:not(.SearchResult--with-subtext) .SearchResult__text-container {
  .SearchResult__text {
    line-height: $result-height;
  }
}
.SearchResult.SearchResult--with-subtext .SearchResult__text-container {
  .SearchResult__text {
    position: absolute;
    top: 0.25rem;
  }
  .SearchResult__subtext {
    position: absolute;
    bottom: 0.25rem;
    color: $text-muted;
    font-size: $font-size-smaller;
  }
}

.SearchResult__secondary-action {
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 0.5rem;
  cursor: pointer;
}
