@import "../styles/spacing";
@import "../styles/colors";

.BulkUserCreate {
  .BulkUserCreate__overlay {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .BulkUserCreate__description {
    @include padding(1rem, "b");
  }
}

.BulkUserCreate__form {
  @include margins(1rem, "t");

  .FileUpload {
    @include padding(1rem, "b");
  }

  .BulkUserCreate__form-errors-wrapper {
    @include margins(1rem, "t");
  }

  .BulkUserCreate__form-errors {
    color: color("error-red");
  }

  .BulkUserCreate__form-footer {
    @include margins(1rem, "t");
  }
}
