@import "../../styles/old/bootstrap";

.UserAgreement {
  max-width: 42rem;
  height: calc(100vh - 15.25rem);
  &__copy {
    height: calc(100% - 15.25rem);
    margin-bottom: 1.75rem;
    padding: 1.25rem;
    overflow-y: scroll;

    border: $btn-border-width solid $ca-cloud-grey;
    border-radius: $border-radius;
  }
  .Card {
    height: inherit;
  }
  .Card__card {
    height: inherit;
  }
  .Card__card-body {
    height: inherit;
  }
  .Checkbox {
    margin-bottom: 1.25rem;
  }
}
