$button-h-spacing: 1rem;
$button-v-spacing: 0.5rem;

// By using a negative margin on the button group, and an equal positive margin on the
// buttons, we can ensure that there is vertical spacing between buttons when they wrap.
.ButtonGroup {
  display: flex;
  margin-top: -$button-v-spacing;
}
.ButtonGroup .Button {
  margin-top: $button-v-spacing;
}

// Every button except the last one should have a margin on the right.
.ButtonGroup .Button:not(:last-child) {
  margin-right: $button-h-spacing;
}
