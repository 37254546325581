@import "../styles/old/variables";
@import "../styles/colors";

.CopyrightFooter {
  color: color("k2-neutrals-disabled-text");
  font-size: $font-size-sm;
  text-align: center;
}

.CopyrightFooter p {
  margin: 0;
}
