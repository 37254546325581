@import "../../../styles/colors";
@import "../../../styles/fonts";

.AddResourceRow {
  display: flex;
  flex-flow: row nowrap;

  > .AddResourceRow__item {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
    margin-bottom: 0.2rem;

    font-size: font-size("is");

    > input[type="text"] {
      width: 100%;
      padding: 0.25rem 1rem;

      border: 0.06rem solid color("readability-grey-lighter");
      border-radius: 0.375rem;
      outline: none;
    }

    > label {
      > input[type="file"] {
        display: none;
      }
    }

    > .SaveTypeButton {
      display: inline-block;
      margin-bottom: 0rem;
      margin-left: 0.5rem;
      padding: 0.25rem 1rem;

      color: color("k2-primary-base");
      font-family: font-family("inter");

      background-color: color("white");
      border: 0.06rem solid color("readability-grey-lighter");
      border-radius: 0.375rem;
      cursor: pointer;

      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      -webkit-user-select: none;
      user-select: none;
    }
    > .SaveTypeButton:hover {
      background-color: color("k2-primary-lighten-4");
      border-color: color("k2-primary-base");
      border-width: 0.06rem;
    }
  }

  > .AddResourceRow__item.ResourceName {
    width: 12rem;
  }
  > .AddResourceRow__item.ResourceType {
    width: 13rem;
  }
  > .AddResourceRow__item.ResourceFile {
    width: 19.5rem;
  }
  > .AddResourceRow__item:last-of-type {
    margin-right: 1rem;
  }
}
