@import "../styles/old/variables";
@import "../styles/colors";

$key-hint-font-size: $font-size-sm;
$key-hint-line-height: 1.5;
$key-hint-border-width: $ca-border-width;

$key-hint-height: ($key-hint-font-size * $key-hint-line-height) +
  ($key-hint-border-width * 2);

.KeyHint {
  display: block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;

  color: color("k2-neutrals-quaternary-text");
  font-size: $key-hint-font-size;
  font-family: $font-family-monospace;
  line-height: $key-hint-line-height;
  text-align: center;

  background: color("k2-neutrals-card-element-bg");
  border: none;
  border-radius: 0.25rem;

  pointer-events: none;
}
