/// Screen sizes, typically to use as breakpoints
/// Inspired by https://tailwindcss.com/docs/breakpoints
///
/// Examples:
///   @media (min-width: screens("sm")) { ... }
///   @media (min-width: map-get($screens, "sm")) { ... }
@import "utilities";

/* stylelint-disable unit-allowed-list */
$screens: (
  "sm": 640px,
  "md": 768px,
  "lg": 1024px,
  "xl": 1280px,
  "2xl": 1536px,
);
/* stylelint-enable unit-allowed-list */

/// Helper for getting a $screens value
@function screen($key) {
  @return map-get-safe($screens, $key, number);
}
