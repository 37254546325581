@import "../../styles/colors";
@import "../../styles/fonts";

.UpdatePassword__content {
  display: inline-flex;
  flex-direction: column;
  max-width: 38rem;
  form {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }
}

.UpdatePassword__overlay {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.UpdatePassword__authzero-content {
  p {
    @include font("tegus-text-text");
    color: color("k2-neutrals-secondary-text-light");
  }
}
