@import "../../styles/colors";
@import "../../styles/spacing";

.MultiSelectListRow {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
  will-change: opacity, transform, visibility;

  .MultiSelectListRow__body {
    @include padding(1rem, "x");
    @include padding(0.625rem, "t");
    @include padding(0.5rem, "b");
    display: flex;
    justify-content: space-between;
    width: 100%;

    color: color("readability-grey-lighter");

    background-color: color("white");
    border-radius: 0.5rem;
    cursor: pointer;

    transition: background-color 0.025s ease-in-out;

    .MultiSelectListRow__display {
      display: flex;
      align-items: center;
      width: 95%;
      height: 1.875rem;

      .MultiSelectListRow__icon {
        @include margins(0.7rem, "r");
        @include padding(0.12rem, "b");
        color: color("readability-grey-dark");
        font-size: 1.25rem;
      }
      .MultiSelectListRow__icon--selected {
        color: color("k2-primary-base");
      }
      .MultiSelectListRow__name {
        max-width: 50%;
        overflow: hidden;

        color: color("readability-grey-dark");
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .MultiSelectListRow__name--selected {
        color: color("k2-primary-base");
      }
      .MultiSelectListRow__subtext {
        @include padding(0.5rem, "l");
        @include margins(0.5rem, "l");
        max-width: 50%;
        overflow: hidden;

        color: color("readability-grey-lighter");
        white-space: nowrap;
        text-overflow: ellipsis;

        border-left: 0.06rem solid color("readability-grey-lightest");
      }
    }
    .MultiSelectListRow__action-icon-container {
      @include padding(0.25rem, "b");
      display: flex;
      align-items: center;

      .MultiSelectListRow__action-icon {
        font-size: 1.125rem;
      }
      .MultiSelectListRow__action-icon--selected {
        color: color("k2-primary-base");
        font-size: 1.25rem;
      }
    }
  }
  .MultiSelectListRow__body:hover {
    color: color("k2-primary-base");
    background-color: color("background-grey-warmer");
  }
  .MultiSelectListRow__body--selected {
    color: color("k2-primary-base");
  }
}
.MultiSelectListRow--visible {
  visibility: visible;
  opacity: 1;
}
