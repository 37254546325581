@import "../../styles/colors";
@import "../../styles/old/variables";
@import "../../styles/spacing";

.MfaInfo--loading {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.MfaInfo .Card__card-body .Card {
  @include padding($grid-gutter-width, "b");
  height: 100%;
  .Card__card {
    @include padding($grid-gutter-width/2);
    height: 100%;
    background-color: color("cloud-grey");
  }
}

.MfaInfo .Card .Card__card > div > img {
  border: 0;
  border-radius: 0;
  box-shadow: none;
}

.MfaInfo__required {
  margin-top: 1rem;
  font-weight: 600;
}
