@import "../../styles/colors";
@import "../../styles/icons";
@import "../../styles/spacing";

.CompaniesTable {
  flex-grow: 1;
  width: 100%;

  .CompaniesTable__header {
    padding-left: 1rem;

    .CompaniesTable__header-buttons {
      display: flex;
      flex-flow: row wrap;
      gap: 0.5rem;
    }
  }

  .CompaniesTable__header-text {
    overflow: hidden;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .CompaniesTable__header-first {
    padding-left: 1.5rem !important;
  }
  .CompaniesTable__cell-first {
    padding-left: 1.5rem !important;
    color: color("readability-grey-dark");
  }

  .CompaniesTable__cell {
    color: color("readability-grey-dark");
  }

  .CompaniesTable__cell-actions {
    padding-right: 0rem;
    padding-left: 0rem;
    outline: none;
  }

  .CompaniesTable__row-spacer {
    flex-grow: 0;
    flex-shrink: 0;
    width: 1.5rem;
  }

  .CompaniesTable__overlay {
    display: flex;
    flex-flow: column nowrap;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    color: color("readability-grey-dark");
    .CompaniesTable__overlay-text {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;
      align-items: center;

      .CompaniesTable__overlay-text-heading {
        font-weight: 500;
        font-size: 1.25rem;
      }
      .CompaniesTable__overlay-text-body {
        text-align: center;
      }
    }
  }

  .CompaniesTable__loading {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

ul {
  list-style: none;
  padding-inline-start: 0;

  li {
    position: relative;
    padding-left: 1rem !important;

    &::before {
      display: none;
    }
  }
}
