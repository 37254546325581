@import "../../../styles/spacing";
@import "../../../styles/colors";
@import "../../../styles/fonts";

.ReplaceResourceModal {
  .ReplaceResourceModal__body {
    @include margins(1.5rem, "b");
    @include padding(1rem);

    background-color: color("cloud-grey");
    border-radius: 0.5rem;

    .ReplaceResourceModal__error-message {
      @include margins(0.5rem, "t");
      height: 1rem;
      color: color("error-red");
      font-size: font-size("is");
    }
  }

  .ReplaceResourceModal__body--save-enabled {
    background-color: #f1fcf1;
  }
  .ReplaceResourceModal__body--error {
    background-color: #fff1f1;
  }

  .ReplaceResourceModal__frame {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    height: 8rem;

    .ReplaceResourceModal__progress-bar {
      width: 90%;
    }

    .ReplaceResourceModal__uploading-text {
      text-align: center;
    }
  }
}
