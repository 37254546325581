@import "../styles/dropdowns";
@import "../common/FancyListItem";

.ContextMenu {
  position: relative;

  &:not(:last-child) {
    margin-bottom: $inter-fli-spacing;
  }
}

.ContextMenu__items {
  position: absolute;
  z-index: 100;
}

.ContextMenu__items > * {
  @include dropdown-item();
  white-space: nowrap;
}
