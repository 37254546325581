/// Icon styles (mostly for Font Awesome icons)
@import "colors";

/// Apply a colour to (the outline of) an icon
/// Use this mixin on the <svg> element of the icon
@mixin icon-color($color-key) {
  // Use a more specific selector s.t. we override any styles FA adds
  & > path {
    fill: color($color-key);
  }
}
