@import "../../../styles/spacing";

.DeleteResourceModal {
  .DeleteResourceModal__body {
    @include margins(1rem, "b");
  }

  .DeleteResourceModal__frame {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    height: 8.625rem;

    .DeleteResourceModal__error-msg {
      text-align: center;
    }
  }
}
