@import "../../styles/colors";

.IconButton {
  .IconButton__frame {
    display: inline-block;
    cursor: pointer;

    > .IconButton__icon {
      color: color("k2-primary-base");
    }
  }
  .IconButton__frame--disabled {
    display: inline-block;

    > .IconButton__icon {
      color: color("readability-grey-lighter");
    }
  }
}
