@import "../styles/old/variables";
@import "../styles/spacing";

.Resources {
  flex-grow: 1;
}

.Resources .Resources__container {
  @include padding(0, "x");
  max-width: 100vw;

  &:not(:last-child) {
    @include margins($grid-gutter-width, "b");
  }
}

.Resources.Resources--loading {
  @include margins($grid-gutter-width, "t");
  display: flex;
  align-items: center;
  justify-content: center;
}
