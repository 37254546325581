/// Font properties
/// Inspired by the font related functionality of Tailwind
@import "utilities";
@import "colors";

$font-sizes: (
  "xxs": 0.688rem,
  "xs": 0.75rem,
  "is": 0.875rem,
  "sm": 1rem,
  "md": 1.25rem,
  "lg": 1.5rem,
  "xl": 2rem,
);

/// Helper for getting $font-sizes. Please default to using the font mixin instead if
/// possible
@function font-size($key) {
  @return map-get-safe($font-sizes, $key, number);
}

// The font families available here must match those available from the @import
// url(...) call in index.scss
$font-families: (
  "roboto": (
    "Roboto",
    "helvetica neue",
    helvetica,
    arial,
    sans-serif,
  ),
  "inter": (
    "Inter",
    "-apple-system",
    "BlinkMacSystemFont",
    "Segoe UI",
    "Roboto",
    "helvetica",
    "arial",
    "sans-serif",
  ),
);

/// Helper for getting $font-families. Please default to using the font mixin instead
/// if possible
@function font-family($key) {
  @return map-get-safe($font-families, $key, list);
}

// The font weights available here must match those available from the @import
// url(...) call in index.scss
$font-weights: (
  "regular": 400,
  "medium": 500,
  "semi-bold": 600,
  "bold": 700,
);

/// Helper for getting $font-weights. Please default to using the font mixin instead
/// if possible
@function font-weight($key) {
  @return map-get-safe($font-weights, $key, number);
}

$font-defaults: (
  "color": color("readability-grey-dark"),
  "font-weight": font-weight("regular"),
  "font-size": font-size("md"),
  "font-family": font-family("inter"),
);

$font-mappings: (
  "paragraph": (
    "font-size": font-size("sm"),
  ),
  "default-muted": (
    "color": color("readability-grey"),
  ),
  "default": $font-defaults,
  "header-tertiary": (
    "color": color("k2-primary-base"),
  ),
  "header-secondary": (
    "font-size": font-size("lg"),
  ),
  "header-primary": (
    "font-size": font-size("xl"),
  ),
  "button-primary": (
    "color": white,
    "font-size": font-size("sm"),
    "font-weight": font-weight("medium"),
  ),
  "button-primary-slim": (
    "color": white,
    "font-size": font-size("xs"),
    "font-weight": font-weight("medium"),
  ),
  "button-secondary": (
    "color": color("k2-primary-base"),
    "font-size": font-size("sm"),
    "font-weight": font-weight("medium"),
  ),
  "button-tertiary": (
    "color": color("k2-primary-base"),
    "font-size": font-size("xs"),
    "font-weight": font-weight("medium"),
  ),
  "side-nav": (
    "font-size": font-size("md"),
  ),
  "tegus-button": (
    "font-size": font-size("is"),
    "font-weight": font-weight("semi-bold"),
  ),
  "tegus-button-small": (
    "font-size": font-size("xs"),
    "font-weight": font-weight("semi-bold"),
  ),
  "tegus-headline-3": (
    "font-size": font-size("md"),
    "font-weight": font-weight("bold"),
  ),
  "tegus-text-subtitle-1": (
    "font-size": font-size("is"),
    "font-weight": font-weight("bold"),
  ),
  "tegus-text-subtitle-2": (
    "font-size": font-size("xs"),
    "font-weight": font-weight("bold"),
  ),
  "tegus-text-med": (
    "font-size": font-size("is"),
    "font-weight": font-weight("medium"),
  ),
  "tegus-text-caption": (
    "font-size": font-size("xxs"),
  ),
  "tegus-text-caption-semi": (
    "font-size": font-size("xxs"),
    "font-weight": font-weight("semi-bold"),
  ),
  "tegus-text-text": (
    "font-size": font-size("is"),
    "font-weight": font-weight("regular"),
  ),
  "tegus-text-large-reg": (
    "font-size": font-size("sm"),
    "font-weight": font-weight("regular"),
  ),
  "tegus-text-large-bold": (
    "font-size": font-size("sm"),
    "font-weight": font-weight("bold"),
  ),
);

/// Apply specific font styles
@mixin font($key) {
  $mapping: map-get-safe($font-mappings, $key, map);
  @each $property, $value in $font-defaults {
    #{$property}: font-prop($key, $property);
  }
}

/// Return a property of a specific font style. Falls back to using defaults
@function font-prop($key, $property) {
  $mapping: map-get-safe($font-mappings, $key, map);
  @if (map-has-key($mapping, $property)) {
    @return map-get($mapping, $property);
  } @else {
    $value: map-get($font-defaults, $property);
    @if ($value == null) {
      @error "#{$property } is an invalid font property";
    }
    @return $value;
  }
}
