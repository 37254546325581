@import "../styles/old/variables";
@import "./mixins";
@import "../styles/colors";
@import "../styles/icons";
@import "../styles/borders";
@import "../styles/fonts";

$chevron-svg: 1rem;

.ChoiceField {
  @include field;
}

.ChoiceField .Dropdown__item:first-child {
  border-top: none; // Prevent duplicate borders
  border-radius: 0;
}

.ChoiceField__label {
  @include field__label;
  @include font("tegus-text-subtitle-1");
}

.ChoiceField__description {
  @include font("tegus-text-text");
  color: color("k2-neutrals-secondary-text");
}

.ChoiceField__field {
  @include field__input;
  display: flex;

  // override padding from field__input so that we can place it on
  // the child span instead
  padding: 0rem;

  background-color: white;
  cursor: pointer;

  user-select: none;
  span {
    padding: $input-padding-y $input-padding-x;
  }
  .ChoiceField__selection-text {
    @include font("tegus-text-large-reg");

    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ChoiceField__arrow-container {
    padding: 0rem 0.75rem;
    border-left: $input-border-base;

    svg.ChoiceField__arrow {
      @include icon-color("k2-primary-base");

      // Simply setting width/height won't work here
      min-width: $chevron-svg;
      max-width: $chevron-svg;
      min-height: $chevron-svg;
      max-height: $chevron-svg;
      margin-top: 0.75rem;
    }
  }
}
.ChoiceField__field--active {
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
