@import "../common/KeyHint";
@import "../styles/colors";
@import "../styles/old/bootstrap";
@import "../styles/old/mixins";
@import "../styles/spacing";
@import "../styles/borders";

$search-box-width: 40rem;
$search-box-height: 3rem;
$search-box-radius: $border-radius;
$search-box-top: ($ca-top-nav-height - $search-box-height) / 2;

$search-box-outer-spacing-x: 1.25rem;

$search-transition: $ca-ui-transition-duration-fast ease-in-out;

@mixin search-box-icon($icon-size, $spacing-x) {
  @include padding(0.5rem);
  position: absolute;
  top: 2.4rem;
  width: 2.25rem;
  height: 2.25rem;
}

.SearchBox {
  position: relative;

  display: flex;
  flex-basis: $search-box-width;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 1;
  justify-content: center;
  height: 100%;
}

.SearchBox__key-hint {
  position: absolute;
  top: 2.875rem;
  right: 3.75rem;
  z-index: 100;

  transition: right $search-transition;
}
.SearchBox--with-query .SearchBox__key-hint {
  right: 5.5rem;
}
.SearchBox:focus-within .SearchBox__key-hint {
  opacity: 0;
}

.SearchBox .SearchBox__clear-icon {
  @include search-box-icon($icon-size: 1.5rem, $spacing-x: 0.5rem);
  right: 3rem;
  z-index: 100;
  display: none;
  cursor: pointer;
  &:hover {
    fill: $ca-yellow !important;
  }
}
.SearchBox.SearchBox--with-query .SearchBox__clear-icon {
  display: block;
}

.SearchBox .SearchBox__search-icon {
  @include search-box-icon($icon-size: 1.25rem, $spacing-x: 0.875rem);
  right: 0.5rem;
  z-index: 100;

  color: color("white");

  background: color("k2-tegus-blue");
  border-radius: $search-box-radius;
}
.SearchBox.SearchBox--with-query-clean .SearchBox__search-icon {
  cursor: pointer;
}

.SearchBox__input-dropdown-wrapper {
  position: absolute;
  top: ($ca-top-nav-height - $search-box-height) / 2;
  right: 0;
  left: 0;

  border: 0.063rem solid color("k2-neutrals-separator");
  border-radius: $search-box-radius;
}

.SearchBox__input {
  @extend .form-control;
  height: $search-box-height;
  padding-right: 4.85rem; // Account for key hint
  padding-left: $search-box-outer-spacing-x;

  color: $ca-black;
  font-weight: 400;
  text-overflow: ellipsis;

  background-color: color("white");
  border: none;
  border-radius: $search-box-radius;

  transition: padding-right $search-transition;

  &::placeholder {
    color: color("k2-neutrals-disabled-text");
  }

  &::-ms-input-placeholder {
    color: color("k2-neutrals-disabled-text");
  }

  &:focus {
    padding-right: $search-box-outer-spacing-x;
    color: $ca-black;
    border-color: color("k2-primary-lighten-4");
    box-shadow: 0 0 0 0.125rem color("k2-primary-lighten-4");
  }
}

.SearchBox--with-query.SearchBox--with-content:focus-within .SearchBox__input {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.SearchBox:not(:focus-within) .SearchDropdownDisplay {
  display: none;
}
