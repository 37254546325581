@import "../../styles/old/variables";
@import "../../styles/spacing";

.MfaSettings .BackupTokens {
  @include padding($ca-content-spacing, "b");
}

.MfaSettings .ResetMfa {
  @include padding($ca-content-spacing, "b");
}
