@import "styles/old/variables";
@import "styles/colors";
@import "styles/spacing";

$app-body-spacing: $ca-content-spacing;
$app-breadcrumb-bottom-padding: $ca-content-spacing / 2;

.App__body {
  @include padding($ca-top-nav-height, "t");
  @include padding($app-body-spacing, "r");
  @include padding($app-body-spacing, "b");
  @include padding($app-body-spacing + $ca-left-nav-width, "l");
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: color("k2-neutrals-surface-bg");
}

.App__breadcrumbs {
  @include padding($app-breadcrumb-bottom-padding, "b");
  flex-grow: 0;
  flex-shrink: 0;
}

.App__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.App__footer {
  flex-grow: 0;
  flex-shrink: 0;
  padding-top: $app-body-spacing;
}

.App__loading-spinner-container {
  display: none;
  & > div {
    width: 100vw;
    height: 100vh;
  }
}

.App.App--content-only {
  .App__body {
    @include padding($app-body-spacing, "t");
    @include padding($app-body-spacing, "l");
  }
}

.App.App--is-loading {
  .TopNav,
  .SideNav,
  .App__body {
    display: none;
  }
  .App__loading-spinner-container {
    display: block;
  }
}
