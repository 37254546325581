/* Custom variables (including Bootstrap overrides) */
// Canalyst colours from the Big Drop style guide
$ca-black: #000000;
$ca-charcoal: #161b21;
$ca-dark-grey: #6d6e71;
$ca-grey: #c3c2c3;
$ca-light-grey: #e2e3e3;
$ca-cloud-grey: #f1f1f1;
$ca-yellow: #ffd200;
$ca-error-red: #e62600;
$ca-green: #259b70;

// Other custom variables
$ca-border-color: $ca-light-grey;
$ca-border-width: 0.0625rem;
$ca-border-width-thick: 0.1875rem;
$sidebar-content-h-spacing: 2.5rem; // Needs to be shared between multiple components

// Navigation constants
$ca-content-spacing: 2.25rem;
$ca-left-nav-width: 13.5rem;
$ca-top-nav-height: 7rem;
$ca-ui-transition-duration: 0.3s;
$ca-ui-transition-duration-fast: 0.15s;

// Bootstrap variable overrides
$ca-font-family-roboto: "Roboto", "helvetica neue", helvetica, arial, sans-serif;
$ca-font-family-inter: "Inter", "-apple-system", "BlinkMacSystemFont",
  "Segoe UI", "Roboto", "helvetica", "arial", "sans-serif";
$grid-gutter-width: $ca-content-spacing;

// Common spacing
$spinner-margin-top: 2.5rem;

// Fonts
$font-family-base: $ca-font-family-inter;

$body-color: $ca-black;
$link-color: $body-color;
$link-decoration: underline;

$headings-font-weight: 500;
$btn-font-weight: 500;
$btn-border-width: 0.175rem;
$input-font-weight: 500;
$input-error-font-weight: 400;
$input-placeholder-color: $ca-dark-grey;

$border-radius: 0.5rem;
$enable-rounded: false;

$body-bg: $ca-cloud-grey;

$primary: $ca-yellow;
$secondary: $ca-black;
$dark: $ca-black;

$code-color: $ca-black;

/* Include Bootstrap variables (which depend on the functions) */
@import "~bootstrap-scss/functions";
@import "~bootstrap-scss/variables";

/* Custom variables (including those that depend on Bootstrap) */
// Don't use RGBA colour so the background doesn't change it.
$bootstrap-border: $card-border-width solid $ca-border-color;
$font-size-smaller: $font-size-base * 0.8;

/* Animations */
$ui-animation-duration: 0.2s;
$ui-animation-slide-up: slide-up $ui-animation-duration ease-out;
$ui-animation-slide-down: slide-down $ui-animation-duration ease-in;
$ui-animation-fade-in: fade-in $ui-animation-duration ease-in;

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(2rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
