@import "../../styles/colors";
@import "../../styles/spacing";

.CompaniesQuickFilter {
  @include margins(0.5rem, "b");
  @include padding(1.5rem, "l");
  @include padding(1rem, "r");

  display: flex;
  width: 100%;
  font-size: 1rem;

  label {
    @include margins(0rem, "b");
    cursor: pointer;
  }

  .CompaniesQuickFilter__icon {
    @include padding(0.75rem, "l");
    @include padding(0.25rem, "r");

    display: flex;
    align-items: center;

    color: color("readability-grey-lighter");

    background-color: color("white");
    border-top: 0.07rem solid #ccc;
    border-bottom: 0.07rem solid #ccc;
    border-left: 0.07rem solid #ccc;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .CompaniesQuickFilter__field {
    @include padding(0.5rem, "l");
    @include padding(0.25rem, "r");
    @include padding(0.25rem, "y");

    display: flex;
    width: 100%;
    height: 2.5rem;

    border-top: 0.07rem solid #ccc;
    border-right: 0rem;
    border-bottom: 0.07rem solid #ccc;
    border-left: 0rem;
  }
  .CompaniesQuickFilter__field:focus {
    outline-width: 0;
  }
  .CompaniesQuickFilter__clear {
    @include padding(0.75rem, "x");
    @include padding(0.12rem, "t");

    display: flex;
    align-items: center;

    background-color: color("white");
    border-top: 0.07rem solid #ccc;
    border-right: 0.07rem solid #ccc;
    border-bottom: 0.07rem solid #ccc;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}
