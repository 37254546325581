@import "../styles/old/mixins";
@import "../styles/old/variables";
@import "./SearchBox";

$result-msg-height: $search-box-height;

.SearchDropdownDisplay {
  background: white;
  border-top: $bootstrap-border;
  border-radius: 0 0 $search-box-radius $search-box-radius;
}
.SearchDropdownDisplay .Spinner {
  height: $result-msg-height;
  line-height: $result-msg-height;
}

.SearchDropdownDisplay__msg {
  height: $result-msg-height;
  line-height: $result-msg-height;
  text-align: center;
}
