@import "../styles/colors";
@import "./TopNav";
@import "../styles/old/variables";

.TopNavMinimal {
  @extend .TopNav;
}

.TopNavMinimal__left-section {
  @extend .TopNav__left-section;
}

.TopNavMinimal__right-section {
  @extend .TopNav__right-section;
  justify-content: end;
  padding-left: 0;
}

.TopNavMinimal__right-section-icon {
  @extend .TopNav__right-section-icon;
}

.TopNavMinimal__heading {
  position: absolute;
  right: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  margin: 2.25rem auto 0 auto;

  color: color("k2-neutrals-secondary-text");
  small {
    margin-left: 1rem;
    color: color("readability-grey-lightest");
    font-size: 60%;
  }
}
