@import "../../styles/colors";
@import "../../styles/spacing";

.CompaniesSidebarSection {
  @include padding(0.75rem, "y");
  @include padding(0.75rem, "r");
  @include padding(1.55rem, "l");

  display: flex;
  gap: 1.2rem;
  align-items: center;
  color: color("readability-grey-dark");

  .CompaniesSidebarSection__icon {
    opacity: 0.75;
  }

  .CompaniesSidebarSection__title {
    font-weight: 500;
    font-size: 1.06rem;
  }
}

.CompaniesSidebarSection__expand-icon {
  @include padding(0.125rem, "r");
  color: color("readability-grey-dark");
}
