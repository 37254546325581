@import "../styles/old/bootstrap";
@import "../styles/spacing";

.ResourceCard {
  .Card {
    display: flex;
    height: 100%;
    padding: 0.25rem;
  }
  .Card__card {
    height: 100%;
    // Mimick the screen rules of col-lg
    /* stylelint-disable-next-line unit-allowed-list */
    @media only screen and (min-width: map-get($grid-breakpoints, "lg")) {
      .ButtonGroup {
        margin-top: auto;
      }
    }

    .Card__card-body {
      display: flex;
      flex-flow: column nowrap;
    }
  }
  .ContextMenu__children > span {
    cursor: pointer;
  }

  .ResourceDisplayItem__button {
    display: flex;
    margin-top: auto;
  }
}
