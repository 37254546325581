@import "../styles/old/variables";
@import "../styles/spacing";

.ResourcesFull {
  flex-grow: 1;
}

.ResourcesFull .ResourcesFull__container {
  @include padding(0, "x");
  max-width: 100vw;
}

.ResourcesFull.ResourcesFull--loading {
  @include margins($grid-gutter-width, "t");
  display: flex;
  align-items: center;
  justify-content: center;
}
