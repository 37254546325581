@import "../styles/old/variables";
@import "./mixins";
@import "../styles/colors";
@import "../styles/icons";
@import "../styles/borders";

$chevron-svg: 1rem;

.InlineChoiceField {
  width: 100%;
  margin-bottom: 0rem;
}

.InlineChoiceField .Dropdown__item:first-child {
  border-top: none; // Prevent duplicate borders
  border-radius: 0;
}

.InlineChoiceField__label {
  @include field__label;
}

.InlineChoiceField__field {
  @include field__input;
  display: flex;

  // override padding from field__input so that we can place it on
  // the child span instead
  padding: 0rem;

  background-color: color("white");
  cursor: pointer;

  user-select: none;
  span {
    padding: 0.25rem $input-padding-x;
  }
  .InlineChoiceField__selection-text {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .InlineChoiceField__selection-text--disabled {
    flex-grow: 1;
    overflow: hidden;
    color: color("readability-grey-lighter");
    text-overflow: ellipsis;
  }
  .InlineChoiceField__arrow-container {
    padding: 0rem 0.5rem;
    border-left: $input-border-base;

    svg.InlineChoiceField__arrow {
      @include icon-color("k2-primary-base");

      min-width: $chevron-svg;
      max-width: $chevron-svg;
      min-height: $chevron-svg;
      max-height: $chevron-svg;
      margin-top: 0.5rem;
    }

    svg.InlineChoiceField__arrow--disabled {
      min-width: $chevron-svg;
      max-width: $chevron-svg;
      min-height: $chevron-svg;
      max-height: $chevron-svg;
      margin-top: 0.5rem;

      color: color("readability-grey-lighter");
    }
  }
}
.InlineChoiceField__field--active {
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.InlineChoiceField__field--disabled {
  background-color: color("background-grey-warmer");
  cursor: default;
}
