@import "../../index.scss";
@import "../../styles/old/variables";
@import "../../styles/fonts";
@import "../../styles/colors";

.ApiTokens {
  flex-grow: 1;
}

.ApiTokens.ApiTokens--loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.ApiTokens:not(.ApiTokens--loading) .Spinner {
  margin-top: $spinner-margin-top;
}

.ApiTokens__list {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ApiTokens__description {
  @include font("tegus-text-text");
  color: color("k2-neutrals-secondary-text-light");
}
