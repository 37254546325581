@import "../../styles/spacing";
@import "../../styles/fonts";

.UpdatePasswordForm {
  display: flex;
  &__fields {
    flex-grow: 1;
    min-width: 24rem;
  }
  &__requirements {
    padding-left: 1.5rem;
    h3 {
      @include font("paragraph");
      @include margins(1rem, "b");
      font-weight: 500;
    }
  }
  &__old-password {
    margin-right: 2.5rem;
  }
}
