@import "../../styles/colors";
@import "../../styles/spacing";

.Banner {
  @include padding(1.5rem, "l");
  @include padding(1rem, "r");
  @include padding(1rem, "y");
  @include margins(2rem, "b");

  display: flex;
  justify-content: space-between;
  width: 100%;

  background-color: color("k2-primary-lighten-3");
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.12);

  .Banner__left {
    display: flex;
    gap: 0.75rem;
    align-items: center;

    .Banner__heading {
      font-weight: 500;
    }
  }

  .Banner__right {
    display: flex;

    .Banner__close-button {
      display: flex;
    }
  }
}
