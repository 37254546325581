@import "../styles/old/variables";
@import "../styles/spacing";
@import "./SideNav";

.StaffMenu .Dropdown .Dropdown__content {
  @include padding(0.5rem);
  width: 100%;
  transform: translateY(-1rem);
}

.StaffMenu .Dropdown .Dropdown__toggle {
  position: relative;
  cursor: pointer;
}
