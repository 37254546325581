@import "../../styles/old/variables";
@import "../../styles/spacing";

/* An arbitrary value for the height at which additional items can potentially
 * be added to the mimimum number in the FolderItemPreviews on the home page */
$page-height-min-items: 53rem;

.HomePage {
  @include margins(-$grid-gutter-width, "b");
}

.HomePage .Card {
  @include margins($grid-gutter-width, "b");
  width: 100%;
}

.List {
  margin-left: 24px;
  list-style-type: disc;
}

.Centered {
  display: flex;
  justify-content: center;
  width: 100%;
}

:export {
  /* stylelint-disable property-no-unknown */
  pageHeightMinItems: $page-height-min-items;
  /* stylelint-enable property-no-unknown */
}
