@import "../../styles/colors";
@import "../../styles/icons";
@import "../../styles/spacing";

$secondary-icon-size: 1.75rem;
$padding: 0.75rem;

.ResourceElem__request-update-icon {
  width: 100%;
  height: 100%;

  svg {
    /* padding applied to inner element s.t. the padding is clickable */
    @include padding($padding, "l");
    @include padding($padding, "r");
    @include icon-color("k2-primary-base");
    z-index: 2;
    max-width: $secondary-icon-size + $padding + $padding;
    max-height: $secondary-icon-size;
  }
}
