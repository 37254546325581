@import "../../styles/colors";
@import "../../styles/icons";

// The bottom of the star has more visual weight, so nudge it up a tad
$star-offset-y: -0.05rem;

.WatchListElem {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
}

.WatchListElem .WatchListElem__star-icon {
  @include icon-color("grey");
  position: absolute;
  transform: translateY($star-offset-y);
}
.WatchListElem.WatchListElem--on .WatchListElem__star-icon {
  @include icon-color("k2-primary-base");
}
.WatchListElem:hover .WatchListElem__star-icon {
  opacity: 0;
}

.WatchListElem .WatchListElem__star-bg-icon {
  @include icon-color("white");

  // Slightly smaller than the foreground start icon; helps the edges look better on
  // non-white backgrounds. Also the solid icon is extra thicc for some reason
  transform: translateY($star-offset-y) scale(0.95);
}
.WatchListElem.WatchListElem--on .WatchListElem__star-bg-icon {
  @include icon-color("k2-primary-base");
}
