@import "../../styles/colors";
@import "../../styles/old/variables";
@import "../../styles/spacing";

.MfaSetupSteps {
  width: 100%;
}

.MfaSetupSteps .Card__card-body .Card {
  @include padding($grid-gutter-width, "b");
  height: 100%;
  .Card__card {
    @include padding($grid-gutter-width/2);
    height: 100%;
    background-color: color("cloud-grey");
  }
}

.MfaSetupSteps--loading {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.MfaSetupSteps__qrcode {
  display: flex;
  justify-content: center;
}
