.EmailSettings--loading {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.EmailSettings__loading-spinner {
  display: flex;
}
