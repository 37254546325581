@import "../../styles/old/variables";

.ItemOrderingWidget .Dropdown {
  display: inline-block;
}

.ItemOrderingWidget .ChoiceField__label {
  margin-right: 0.75rem;
}
.ItemOrderingWidget .ChoiceField__field {
  min-width: 15rem;
}
