/* STYLE GUIDELINES:
 *
 * Refer to the company-wide style guide on our wiki:
 *    https://gitlab.com/canalyst/wiki/-/tree/master/canalyst-style-guide
 *
 * Refer to the Sass style guide as well:
 *    https://gitlab.com/canalyst/wiki/-/blob/master/sass/sass-styleguide.md
 *
 * Here are some additional guidelines specific to this project:
 *
 * 1. Override Bootstrap variables to achieve general style changes. Add custom styles
 *    as a last resort. See node_modules/bootstrap-sass/_variables.scss.
 */

// See https://fonts.google.com/specimen/Roboto?selection.family=Roboto:ital,wght@0,400;0,500;1,400
// The fonts imported here must correspond with the styles in _fonts.scss
// prettier-ignore
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,400&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

@import "styles/colors";
@import "styles/spacing";

// Global & reset styles
ul {
  list-style: none;
  padding-inline-start: 0;

  li {
    position: relative;
    padding-left: 2rem;

    &::before {
      position: absolute;
      left: 0;

      width: 2rem;

      color: color("yellow");
      text-align: center;

      content: "+";
    }
  }
}

// Minimum content size until we have proper mobile styles
$content-min-width: 64rem;
body {
  min-width: $content-min-width;
}
.TopNav,
.TopNavMinimal {
  min-width: $content-min-width;
}
