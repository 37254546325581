@import "../../styles/old/variables";
@import "../../styles/colors";

.RequirementsCopy {
  li::before {
    content: "";
  }
  li {
    padding: 0;
    svg {
      margin-right: 0.25rem;
    }
  }
}

.RequirementsCopy__error {
  color: $ca-error-red;
  font-weight: $input-error-font-weight;
  font-family: $ca-font-family-inter;
}

.RequirementsCopy__valid {
  color: $ca-green;
  font-weight: $input-error-font-weight;
  font-family: $ca-font-family-inter;
}
