@import "../styles/colors";

.Steps {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin: 0 0.5rem 0 0.5rem;

  border-radius: 50%;
}

.Steps__inactive {
  background-color: color("dark-grey");
}

.Steps__active {
  background-color: color("k2-primary-darken-1");
}
