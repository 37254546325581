@import "../styles/colors";
@import "../styles/dropdowns";

/// Apply specific dropdown styles for a dropdown used as a menu in a FLI
@mixin fli-dropdown {
  &.Dropdown {
    height: 100%;
  }
  &.Dropdown .Dropdown__toggle {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
  }
  &.Dropdown .Dropdown__content {
    left: unset;
    white-space: nowrap;
    transform: translate(-0.25rem, -0.25rem);
  }
}

.Dropdown {
  position: relative;
}
.Dropdown__item {
  @include dropdown-item();
}

.Dropdown__item--disabled {
  color: $text-muted;
  cursor: not-allowed;
  user-select: none;
  &:hover {
    background-color: white;
  }
}

.Dropdown .Dropdown__content {
  position: absolute;
  z-index: 9999;

  display: none;
  min-width: 12rem;
  padding-left: 0;

  // Remove default unordered list styles
  li::before {
    content: none;
  }
}
.Dropdown.Dropdown--active .Dropdown__content {
  display: block;
}
