@import "../styles/colors";
@import "../styles/spacing";

.UserCreationErrors {
  .UserCreationErrors__description {
    @include padding(1rem, "b");
  }

  .UserCreationErrors__error {
    color: color("error-red");
  }

  .UserCreationErrors__error:first-letter {
    text-transform: capitalize;
  }

  .UserCreationErrors__footer {
    margin-top: 1rem;
  }

  .SimpleTable {
    margin-top: 0.5rem;
  }
}
