@import "../../../styles/colors";
@import "../../../styles/fonts";

.MultiUpload {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1rem;
  padding: 1rem;

  background-color: color("cloud-grey");
  border-radius: 0.5rem;

  > .MultiUpload__row {
    display: flex;
    flex-flow: row nowrap;

    > .MultiUpload__item--header {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;

      font-weight: font-weight("medium");
    }
    > .MultiUpload__item--header.ResourceFile {
      width: 19.5rem;
    }
    > .MultiUpload__item--header.ResourceType {
      width: 13rem;
    }
    > .MultiUpload__item--header:last-of-type {
      margin-right: 0rem;
    }

    > .MultiUpload__item {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;
      font-size: font-size("is");
    }
    > .MultiUpload__item:last-of-type {
      margin-right: 0rem;
    }
  }
  .MultiUpload__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;

    .ErrorMessage {
      display: flex;
      align-items: center;
      color: color("error-red");
      font-size: font-size("is");
    }
  }
}
