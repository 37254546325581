@import "../../styles/colors";
@import "../../styles/spacing";

.CompaniesTableToolbar {
  @include padding(1rem, "x");
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  border-top: 0.07rem solid #ccc;

  .CompaniesTableToolbar__left {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
  .CompaniesTableToolbar__right {
    display: flex;
    align-items: center;
    height: 3.125rem;
    font-size: 1rem;

    .CompaniesTableToolbar__updated {
      display: flex;
      flex-flow: row nowrap;
      gap: 0.5rem;
      align-items: center;

      .CompaniesTableToolbar__updated-section {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        white-space: nowrap;
        column-gap: 0.5rem;

        row-gap: 0rem;

        .CompaniesTableToolbar__updated-text {
          color: color("readability-grey-dark");
        }

        .CompaniesTableToolbar__updated-display {
          color: color("readability-grey-light");
        }
      }
    }

    .CompaniesTableToolbar__rows-display {
      display: flex;
      flex-flow: row wrap;
      gap: 0.25rem;
      justify-content: center;

      white-space: nowrap;

      .CompaniesTableToolbar__rows-count-section {
        display: flex;
        flex-flow: row nowrap;
        gap: 0.25rem;

        .CompaniesTableToolbar__rows-count {
          color: color("readability-grey-light");
        }
      }
    }

    .CompaniesTableToolbar__separator {
      @include padding(1rem, "r");
      @include margins(1rem, "r");

      height: 100%;
      border-right: 0.06rem solid #ccc;
    }
  }
}
