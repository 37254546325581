@import "../styles/old/bootstrap";
@import "../styles/spacing";

$width-0: 0rem;
$width-xs: 64rem;
$width-s: 100rem;
$width-m: 125rem;
$width-l: 150rem;

// mixin to determine when to show col
@mixin breakpoint-cols($breakpoint, $ncols) {
  @media only screen and (min-width: $breakpoint) {
    div:nth-child(-n + #{$ncols}) {
      display: block;
    }
  }
}

// Custom mixin to make sure column sizing is observed. Much of the mixin is
// copied from Bootstrap's row-cols mixin. See
// node_modules/bootstrap-scss/mixins/_grid.scss
@mixin col-sizing($ncols) {
  > * {
    flex: 0 0 auto !important;
    max-width: percentage(1 / $ncols) !important;
  }
}

// mixin to ensure width-consistent column layout
@mixin col-layout($is-hide-row) {
  @if $is-hide-row {
    @media (max-width: $width-xs) {
      .ResourceCard:not(:nth-child(-n + 1)) {
        @include padding(2.25rem, "t");
      }
    }
    @media (min-width: $width-xs) and (max-width: $width-s) {
      @include col-sizing(2);
      .ResourceCard:not(:nth-child(-n + 2)) {
        @include padding(2.25rem, "t");
      }
    }
    @media (min-width: $width-s) and (max-width: $width-m) {
      @include col-sizing(3);
      .ResourceCard:not(:nth-child(-n + 3)) {
        @include padding(2.25rem, "t");
      }
    }
  }
  @media (min-width: $width-m) and (max-width: $width-l) {
    @include col-sizing(4);
  }
  @media (min-width: $width-l) {
    @include col-sizing(5);
  }
}

.ResourcesDisplay--hide-row {
  @include breakpoint-cols($width-0, 1);
  @include breakpoint-cols($width-xs, 2);
  @include breakpoint-cols($width-s, 3);
  @include breakpoint-cols($width-m, 4);
  @include breakpoint-cols($width-l, 5);

  div {
    display: none;
  }
}
.ResourcesDisplay--hide-row .ButtonGroup {
  display: block;
}

.ResourcesDisplay--full-grid .Card {
  @include margins(0rem, "b");
}

.ResourcesDisplay--stack-row {
  @include col-layout($is-hide-row: false);

  .ResourceCard:last-child {
    .Card {
      @include margins(0rem, "b");
    }
  }
}

div.ResourcesDisplay--full-grid,
.ResourcesDisplay--hide-row {
  @include row-cols(1);
  @include col-layout($is-hide-row: true);
}

@media only screen and (max-width: 64rem) {
  .ResourcesDisplay--stack-row .Card {
    @include margins(1.25rem, "b");
  }
}
