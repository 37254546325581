@import "../../styles/old/mixins";
@import "../../styles/spacing";

.Versions {
  flex-grow: 1;
}

.Versions.Versions--loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.Versions__footer {
  @include padding(1rem, "t");
  display: flex;
}

.Versions__button {
  @include padding(1rem, "x");
  height: 2.5rem;
}

.Versions__page-num {
  display: grid;
  flex-grow: 1;
  align-items: center;
  justify-items: center;

  color: $text-muted;
  font-size: $font-size-sm;
}
