@import "../styles/old/variables";

.UserSetupDisplay {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-top: $ca-top-nav-height;
}

.UserSetupDisplay .Spinner {
  align-self: center;
}
