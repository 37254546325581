@import "../../styles/spacing";

.CompaniesTableDownloadMenu__loading {
  @include padding(1rem, "x");
  @include padding(0.25rem, "y");

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 10rem;
}
