@import "../../../styles/colors";
@import "../../../styles/fonts";
@import "../../../styles/spacing";

.AddFolderModal {
  @include padding(1rem);
  position: relative;

  .AddFolderModal__input-box {
    @include margins(1rem, "b");
  }

  .AddFolderModal__error-message {
    display: flex;
    align-items: center;
    color: color("error-red");
    font-size: font-size("is");
  }
}
