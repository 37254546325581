.VariantPreference__loading {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.VariantPreference__loading--spinner {
  display: flex;
}

.VariantDimensionsDropdowns {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  margin-bottom: 2rem;
}
