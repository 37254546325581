@import "../../styles/colors";
@import "../../styles/spacing";
@import "../../styles/fonts";

.EmailRecentDownloadUpdate {
  @include margins(1.25rem, "b");
}

.EmailRecentDownloadUpdate__title {
  @include margins(1rem, "b");
  @include font("tegus-text-subtitle-1");
}

.EmailRecentDownloadUpdate__description {
  @include margins(1rem, "b");
  @include font("tegus-text-text");
  color: color("k2-neutrals-secondary-text-light");
}
