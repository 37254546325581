@import "../../styles/spacing";

.CompaniesSidebarCategoryList__loading {
  @include padding(1rem, "y");

  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}
