@import "../styles/colors";
@import "../styles/fonts";
@import "../styles/spacing";

.Companies {
  @include padding(1.5rem);

  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  width: 100%;
  min-width: 58.25rem;
  height: calc(100vh - 17.5rem);

  background-color: color("white");
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.12);

  .Companies__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    color: color("readability-grey-dark");
    font-weight: 400;
    font-size: 1.5rem;
    font-family: font-family("inter");
    line-height: 1.2;

    .Companies__header-left {
      display: flex;
      gap: 3rem;
    }

    .Companies__header-right {
      display: flex;
      gap: 0.75rem;
      align-items: center;
    }
  }

  .Companies__body {
    display: flex;
    flex-flow: row nowrap;
    min-width: 53.25rem;
    height: 100%;

    border: 0.07rem solid #ccc;
    border-radius: 0.5rem;

    .Companies__sidebar-column {
      max-height: calc(100vh - 23.5rem);

      .Companies__sidebar-column-frame {
        height: 100%;
        border-right: 0.07rem solid #ccc;
      }
    }
    .Companies__table-column {
      display: flex;
      width: 100%;

      .Companies__table-loading {
        display: flex;
        flex-flow: column nowrap;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.Companies__loading {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 17.5rem);
}

*::-webkit-scrollbar-track {
  display: none;
}
