// Disabling as stylelint doesn't allow class names with numbers in
/* stylelint-disable */
.MfaSettingsAuth0--loading {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.MfaSettingsAuth0 .RegenerateRecoveryToken {
  max-width: 100vw;
  padding-bottom: 2rem;
}
/* stylelint-enable */
